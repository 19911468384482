
import { useAppStore } from '@bd/admin/store'
import { UserRole } from '@bd/api'
import { MeetingOrganizer, UserCalendarEventDetailsDto } from '@bd/api'
import {
  CalendarEventSaveView,
  InjectTokens,
  useBreakpoints,
  UserType,
  EventSaveMessages,
  EmptyState,
} from '@bd/components'
import { LoadableOptional } from '@bd/helpers'
import { isEventSaveValid } from '@bd/store-modules/modules/calendar-event/getters-helpers'
import { useToast } from 'primevue/usetoast'
import { computed, defineComponent, onMounted, provide, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import AppContentLayout from '@bd/admin/components/AppContentLayout.vue'

const EventEditRouteName = 'CalendarEventEdit'

export default defineComponent({
  components: {
    AppContentLayout,
    CalendarEventSaveView,
    EmptyState,
  },
  setup() {
    const { t } = useI18n()
    const route = useRoute()
    const router = useRouter()
    const store = useAppStore()
    const toast = useToast()
    /* No need for reactivity here - page title should only depend on whether it's edit mode or not */
    const eventId = +route.params.id
    const isEditMode = route.name === EventEditRouteName && !!eventId
    const pageTitle = t(
      `calendar.events.save_event.${isEditMode ? 'edit' : 'add'}_title`,
    )
    const saveBtnLabel = t(
      isEditMode ? 'save' : 'calendar.events.save_event.add_action',
    )
    const { width, sizes } = useBreakpoints()
    const isDesktopSize = computed(() => width.value > sizes.md)
    const event = computed<
      LoadableOptional<UserCalendarEventDetailsDto | undefined>
    >(() => ({
      payload: eventId ? store.state.calendar?.eventDetails : undefined,
    }))

    const canSave = computed(() => isEventSaveValid(store))
    const submitPending = ref(false)

    const selectedAgentId = computed(
      () => store.state.calendar?.selectedAgentId,
    )

    const userType = ref(UserType.Admin)
    provide(InjectTokens.eventSaveUserType, userType)

    const setInitialEventSaveData = async (
      event: UserCalendarEventDetailsDto | undefined,
    ) => {
      if (!isEditMode || !event) {
        await store.dispatch('calendarEvent/clearEventSaveData')
        return
      }
      await store.dispatch('calendarEvent/setEventSaveInitialData', event)
      if (selectedAgentId.value) {
        store.dispatch(
          'calendarEvent/setSelectedAgentId',
          selectedAgentId.value,
        )
      }
    }

    const meetingOrganizer = computed<MeetingOrganizer>(() => {
      const userRoles = store.state.userProfile?.user.roles

      if (userRoles?.includes(UserRole.ADMIN)) {
        return MeetingOrganizer.ADMIN
      }
      if (userRoles?.includes(UserRole.CALL_CENTER)) {
        return MeetingOrganizer.CALL_CENTER
      }
      return MeetingOrganizer.AGENT
    })

    const fetchMeetingTypes = async () => {
      await store.dispatch(
        'staticData/predefinedMeetingTypes',
        meetingOrganizer.value,
      )
    }

    const saveEvent = async () => {
      submitPending.value = true
      try {
        const eventId = await store.dispatch('calendarEvent/saveEvent')
        toast.add(EventSaveMessages.saveSuccess)
        router.push({ name: 'CalendarEventDetails', params: { id: eventId } })
      } catch (err) {
        toast.add(EventSaveMessages.saveError)
      } finally {
        submitPending.value = false
      }
    }

    onMounted(async () => {
      await fetchMeetingTypes()
      if (event.value) {
        setInitialEventSaveData(event.value.payload?.content)
      }
    })

    return {
      t,
      pageTitle,
      saveBtnLabel,
      eventId,
      isDesktopSize,
      event,
      isEditMode,
      canSave,
      saveEvent,
      submitPending,
      selectedAgentId,
    }
  },
})
