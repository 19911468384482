<template>
  <AppContentLayout class="calendar-event-save" backAction>
    <template #back-action>
      <Button
        @click="$router.back()"
        icon="pi pi-chevron-left"
        class="p-button-secondary p-button-circle"
      />
    </template>
    <header class="page-header d-none d-md-flex">
      <h1 class="page-title">{{ pageTitle }}</h1>
      <Button
        class="p-button-primary ml-auto px-5"
        :label="saveBtnLabel"
        :disabled="!canSave || submitPending"
        @click="saveEvent"
      />
    </header>

    <template v-if="selectedAgentId || isEditMode">
      <CalendarEventSaveView class="admin" />
      <div class="d-md-none save-button-container flex-centered">
        <Button
          class="p-button-primary save-button"
          :label="saveBtnLabel"
          :disabled="!canSave || submitPending"
          @click="saveEvent"
        />
      </div>
    </template>
    <EmptyState
      :title="t('calendar.events.save_event.no_agent.title')"
      :description="t('calendar.events.save_event.no_agent.description')"
      v-if="!selectedAgentId && !isEditMode"
    >
      <Button
        icon="pi pi-arrow-left"
        class="p-button-primary px-3"
        :label="t('calendar.event_details.empty_state.back')"
        @click="$router.push({ name: 'Calendar' })"
      />
    </EmptyState>
  </AppContentLayout>
</template>

<script lang="ts">
import { useAppStore } from '@bd/admin/store'
import { UserRole } from '@bd/api'
import { MeetingOrganizer, UserCalendarEventDetailsDto } from '@bd/api'
import {
  CalendarEventSaveView,
  InjectTokens,
  useBreakpoints,
  UserType,
  EventSaveMessages,
  EmptyState,
} from '@bd/components'
import { LoadableOptional } from '@bd/helpers'
import { isEventSaveValid } from '@bd/store-modules/modules/calendar-event/getters-helpers'
import { useToast } from 'primevue/usetoast'
import { computed, defineComponent, onMounted, provide, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import AppContentLayout from '@bd/admin/components/AppContentLayout.vue'

const EventEditRouteName = 'CalendarEventEdit'

export default defineComponent({
  components: {
    AppContentLayout,
    CalendarEventSaveView,
    EmptyState,
  },
  setup() {
    const { t } = useI18n()
    const route = useRoute()
    const router = useRouter()
    const store = useAppStore()
    const toast = useToast()
    /* No need for reactivity here - page title should only depend on whether it's edit mode or not */
    const eventId = +route.params.id
    const isEditMode = route.name === EventEditRouteName && !!eventId
    const pageTitle = t(
      `calendar.events.save_event.${isEditMode ? 'edit' : 'add'}_title`,
    )
    const saveBtnLabel = t(
      isEditMode ? 'save' : 'calendar.events.save_event.add_action',
    )
    const { width, sizes } = useBreakpoints()
    const isDesktopSize = computed(() => width.value > sizes.md)
    const event = computed<
      LoadableOptional<UserCalendarEventDetailsDto | undefined>
    >(() => ({
      payload: eventId ? store.state.calendar?.eventDetails : undefined,
    }))

    const canSave = computed(() => isEventSaveValid(store))
    const submitPending = ref(false)

    const selectedAgentId = computed(
      () => store.state.calendar?.selectedAgentId,
    )

    const userType = ref(UserType.Admin)
    provide(InjectTokens.eventSaveUserType, userType)

    const setInitialEventSaveData = async (
      event: UserCalendarEventDetailsDto | undefined,
    ) => {
      if (!isEditMode || !event) {
        await store.dispatch('calendarEvent/clearEventSaveData')
        return
      }
      await store.dispatch('calendarEvent/setEventSaveInitialData', event)
      if (selectedAgentId.value) {
        store.dispatch(
          'calendarEvent/setSelectedAgentId',
          selectedAgentId.value,
        )
      }
    }

    const meetingOrganizer = computed<MeetingOrganizer>(() => {
      const userRoles = store.state.userProfile?.user.roles

      if (userRoles?.includes(UserRole.ADMIN)) {
        return MeetingOrganizer.ADMIN
      }
      if (userRoles?.includes(UserRole.CALL_CENTER)) {
        return MeetingOrganizer.CALL_CENTER
      }
      return MeetingOrganizer.AGENT
    })

    const fetchMeetingTypes = async () => {
      await store.dispatch(
        'staticData/predefinedMeetingTypes',
        meetingOrganizer.value,
      )
    }

    const saveEvent = async () => {
      submitPending.value = true
      try {
        const eventId = await store.dispatch('calendarEvent/saveEvent')
        toast.add(EventSaveMessages.saveSuccess)
        router.push({ name: 'CalendarEventDetails', params: { id: eventId } })
      } catch (err) {
        toast.add(EventSaveMessages.saveError)
      } finally {
        submitPending.value = false
      }
    }

    onMounted(async () => {
      await fetchMeetingTypes()
      if (event.value) {
        setInitialEventSaveData(event.value.payload?.content)
      }
    })

    return {
      t,
      pageTitle,
      saveBtnLabel,
      eventId,
      isDesktopSize,
      event,
      isEditMode,
      canSave,
      saveEvent,
      submitPending,
      selectedAgentId,
    }
  },
})
</script>

<style lang="scss" scoped>
:deep(.calendar-event-save) {
  @include breakpoint-down(md) {
    height: 100%;
  }
  @include breakpoint-up(md) {
    margin: 0 auto;
    max-width: 80%;
    padding-bottom: 2rem;
    height: 100%;
  }
  @include breakpoint-up(xl) {
    max-width: 90%;
  }
  @include breakpoint-up(xxl) {
    max-width: 80%;
  }
}
.page-header {
  margin-top: 2rem;
  align-items: center;
  padding-bottom: 4rem;
  border-bottom: $divider-border;
}
.save-button-container {
  background-color: $white;
  padding: 1rem;
  margin-top: auto;

  .save-button {
    width: 100%;
  }
}
</style>
